<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="sale">
        <txa></txa>
        <div class="con">
            <div class="left">
                <div class="tit">
                    <div style="display: flex;align-items: center;justify-content: center;">
                        销售模块
                    </div>
                    <ul>
                        
                        <li v-for='(i,index) in text'  :key="index">
                            <p @click="dian_left_list(index)"  :class="{ 'left_biao':index == left_index}" >{{i.name}}</p>
                        </li>
                    </ul>
                    <!-- <p class="p2" @click="jinru_shouye"><img src="../assets/house.png">回到功能区</p> -->
                </div>
                <i class="el-icon-d-arrow-right"></i>
            </div>
            <div class="box">
                <router-view />
            </div>
        </div>
        <zongqiu class="xuanfu" v-drag></zongqiu>
    </div>
</template>

<script>
import kehu from './xiaoshou/Cusman'// 客户管理
import zongqiu from '../components/Zonganniu'
import kanban from './xiaoshou/Kanban'
import kanbanlist from './xiaoshou/Kanbanlist'
import txa from '../components/Txa'
export default {
  name: 'sale',
  data () {
    return {
      text: [
        {
          name: '客户管理'
        },
        {
          name: '销售管理'
        },
        {
          name: '销售分析'
        },
        // {
        //   name: '会员管理1'
        // },
        // {
        //   name: '会员分析2'
        // }
      ],
      tit_list: [
        // '销售目标',
        // '产品目标',
        // '核算目标',
        // '风控目标',
        // '成长目标',
      ],
      inde: '0',
      left_index: '0',
      path: ''
    }
  },
  components: { txa, kehu, kanban, kanbanlist, zongqiu },
  created () {
    // if (sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != null&&sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != undefined&&sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != '') {
    //     this.left_index = JSON.parse(sessionStorage.getItem("pc_mlbb_xiaoshou_biao"));
    // }else{
    //     this.left_index='0'
    // }
    this.jianting(this.$route.path)
    this.path = this.$route.path
  },
  mounted () {
  },
  watch: {
    $route () {
      let date = ''
      date = this.$route.fullPath
      this.jianting(date)
    }
  },
  methods: {
    jianting (i) {
      if (i == '/cusman' || i == '/assignedlist' || i == '/importexport') {
        this.left_index = '0'
      } else if (i == '/salesman' || i == '/kanban' || i == '/importexport_xiaoshou') {
        this.left_index = '1'
      } else if (i == '/salesana') {
        this.left_index = '2'
      } else if (i == '/vipAdimn') {
        this.left_index = '3'
      } else if (i == '/vipDetail') {
        this.left_index = '4'
      }
    },
    // 左侧点击
    dian_left_list (index) {
      // sessionStorage.setItem("pc_mlbb_xiaoshou_biao", JSON.stringify(index));
      if ((this.left_index != 0 && index == 0) || (index == 0 && this.path != '/cusman')) {
        this.$router.push('/cusman')
      } else if ((this.left_index != 1 && index == 1) || (index == 1 && this.path != '/salesman')) {
        this.$router.push('/salesman')
      } else if ((this.left_index != 2 && index == 2) || (index == 2 && this.path != '/salesana')) {
        this.$router.push('/salesana')
      } else if ((this.left_index != 3 && index == 3) || (index == 3 && this.path != '/vipAdimn')) {
        this.$router.push('/vipAdimn')
      } else if ((this.left_index != 4 && index == 4) || (index == 4 && this.path != '/vipDetail')) {
        this.$router.push('/vipDetail')
      }
      this.left_index = index
      // if(index==0){
      //     this.$refs.dakai_sanqiu.dianji_fanhui()
      // }
    },
    dianji_guanbi () {
      this.$refs.qiqiu_guanbi.guanbi_qiu()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sale {
    background:#F4F7FD;
    min-height: 100vh;
    .xuanfu{
        position: absolute;
        z-index: 100;
        top:4.3rem;
  		left:1.5rem;
    }
    .con{
        display: flex;
        justify-content: left;
        .left{
            background: #fff;
            position: relative;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 111;
            width:0.4rem;
            transition: width 1s;
            -webkit-transition: width 1s; /* Safari */
            .tit{
                display: none;
                div{
                    height:0.8rem;
                    // width:1.3rem;
                    background:#9a86db;
                    font-size: 0.22rem;
                    color:#FFFFFF;
                    text-align: center;
                    line-height: 0.8rem;
                }
                ul{
                    li{
                        padding: 0.18rem 0;
                        border-bottom: 0.01rem solid #EEEEEE;
                        cursor: pointer;
                        p{
                            text-align: center;
                            display: block;
                            line-height: 0.41rem;
                            font-size: 0.16rem;
                            color:#888;
                        }
                        .left_biao{
                            color:#9a86db;
                            border-right:0.02rem solid #9a86db;
                        }
                    }
                    
                }
                .p2{
                    display: flex;
                    color:#888888;
                    font-size: 0.14rem;
                    height:0.46rem;
                    width:2.05rem;
                    justify-content: center;
                    align-items: center;
                    border:0.01rem solid #888;
                    border-radius: 0.04rem;
                    position: absolute;
                    bottom: 0.3rem;
                    left:0.27rem;
                    cursor: pointer;
                    img{
                        margin-right: 0.06rem;
                        height:13.0.06rem;
                        width:13.0.06rem;
                    }
                }
            }
            &:hover{
                width:1.3rem;
                .tit{
                    display: block;
                }
                i{
                    display: none;
                }
            }
            i{
                position: absolute;
                display: block;
                font-size: 0.18rem;
                top:50%;
                left: 50%;
                margin-top: -0.18rem;
                margin-left: -0.09rem;
            }
        }
        .box{
            width:100%;
            min-height: 100%;
            margin-left:0.4rem;
            height: 78px;
            background-color: #fff;
        }
        // @media screen and (max-width: 1200px) {
        //     .box{
        //         width:1200px;
        //     }
        // }
    }
    .qiqiu{
        position: absolute;
        right:0.88rem;
        top:0.2rem;
        z-index: 99;
    }
}
@media screen and (max-width: 1200px) {
  .aspreackeven {
    width:1200px;
  }
}
@media screen and (max-height: 716px) {
  .left {
    height:716px;
  }
}
</style>
